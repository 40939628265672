import Vue from 'vue'

export const mutationTypes = {
  SET_ACCOUNT(state, payload) {
    console.log('SET_ACCOUNT')
    console.log(payload)

    state.account = payload.account
  }
}
