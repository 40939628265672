<template>
  <v-container class="news-detail">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-newspaper-variant-outline</v-icon>
      &nbsp;
      <span>お知らせ詳細</span>
    </v-toolbar>

    <v-card flat>
      <v-card-text class="news-detail-tabs-items">
        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">ID</v-col>
          <v-col cols="10" md="10" class="news-detail-item-value" v-if="news !== null">{{ news.id }}</v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">状態</v-col>
          <v-col cols="10" md="10" class="news-detail-item-value" v-if="news !== null">{{ newsPublishedStatus(news) }}</v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">NEW表示</v-col>
          <v-col cols="10" md="10" class="news-detail-item-value" v-if="news !== null">{{ newsIsNew(news) }}</v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">件名</v-col>
          <v-col cols="10" md="10" class="news-detail-item-value" v-if="news !== null">{{ news.title }}</v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">本文</v-col>
          <v-col cols="10" md="10" class="news-detail-item-value news-detail-content" v-if="news !== null && news.content !== ''" v-html="news.content"></v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">外部URL</v-col>
          <v-col cols="10" md="10" class="news-detail-item-value" v-if="news !== null && news.url !== ''">
            <a :href="news.url" target="_blank">
              {{ news.url }}
            </a>
            <v-icon small>mdi-open-in-new</v-icon>
          </v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">公開日時</v-col>
          <v-col
            cols="10" md="10"
            class="news-detail-item-value"
            v-if="news !== null">
            {{ news.publishAt }}
          </v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-item">
          <v-col cols="2" md="2" class="news-detail-item-label">公開終了日時</v-col>
          <v-col
            cols="10" md="10"
            class="news-detail-item-value"
            v-if="news !== null && news.closeAt !== null">
            {{ news.closeAt }}
          </v-col>
        </v-row>

        <v-row justify="space-between" class="news-detail-actions">
          <v-col cols="1" md="1">&nbsp;</v-col>
          <v-col cols="10" md="10">
            <v-row justify="space-between">
              <v-btn to="/news/">
                一覧に戻る
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                v-if="news !== null"
                color="error"
                @click="deleteNews(news.id)"
              >
                お知らせを削除
              </v-btn>

              &nbsp;&nbsp;&nbsp;&nbsp;

              <v-btn
                v-if="news !== null"
                color="warning"
                :to="'/news/' + String(newsId) + '/edit'"
              >
                お知らせを編集
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="2" md="2">&nbsp;</v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import newsHelper from '../../../../lib/newsHelper'

export default {
  data: function () {
    return {
      news: null,

      submittable: true,
    }
  },
  watch: {
  },
  computed: {
    newsId () {
      return parseInt(this.$route.params.id)
    }
  },
  mounted: function () {
    this.$store.dispatch('loadNews', {
      newsId: this.newsId,
      succeeded: function (data) {
        this.news = data.news
      }.bind(this)
    })
  },
  methods: {
    newsPublishedStatus (news) {
      if (newsHelper.published(news.publishAt)) {
        return (newsHelper.closed(news.closeAt)) ? '公開終了' : '公開中'
      } else {
        return '下書き／未公開'
      }
    },

    newsIsNew (news) {
      return news.isNew ? 'NEW表示あり' : 'なし'
    },

    deleteNews () {
      console.log('deleteNews')

      if (!confirm('削除すると元に戻すことはできません。\nこのお知らせを削除しますか？')) {
        return
      }

      if (!this.submittable) {
        return
      }

      this.submittable = false

      this.$store.dispatch('deleteNews', {
        newsId: this.newsId,
        succeeded: function (data) {
          this.$router.push('/news/')
        }.bind(this),
        failed: function () {
          alert('削除できませんでした')
          this.submittable = true
        }.bind(this)
      })
    },
  }
}
</script>

<style scoped>
.news-detail {

}
.news-detail-item {

}
.news-detail-item-label {
  color: grey;
  text-decoration: underline;
}
.news-detail-item-value {
}
.news-detail-content {
  border: 1px solid silver;
  border-radius: 3px;
  padding: 5px 10px;
}
.news-detail-actions {
  margin-top: 30px !important;
}
</style>
