import axios from '../../../lib/axios'
import Noty from 'noty'
import VueNoty from 'vuejs-noty'

const API_BASE_PATH = '/admin/api'

VueNoty.showSucceeded = function (msg) {
  new Noty({
    type: 'success',
    text: msg,
    timeout: 3000,
    progressBar: true,
    layout: 'topCenter',
    theme: 'semanticui'
  }).show()
}

VueNoty.showErrored = function (error, fallbackMessage) {
  let msg = ''
  if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined) {
    msg = error.response.data.message
  } else {
    msg = fallbackMessage
  }

  new Noty({
    type: 'error',
    text: msg,
    timeout: 3000,
    progressBar: true,
    layout: 'topCenter',
    theme: 'semanticui'
  }).show()
}

export const actionTypes = {
  updateAccount (ctx, payload) {
    console.log('[action] updateAccount')
    console.log(payload)

    axios.get(API_BASE_PATH + '/accounts/me')
      .then(response => {
        console.log('updateAccount responded')
        console.log(response)

        ctx.commit('SET_ACCOUNT', response.data)

        if (payload !== undefined && payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('updateAccount failed')
        console.log(error)

        VueNoty.showErrored(error, 'アカウント情報を取得できませんでした')

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  passportStats (ctx, payload) {
    console.log('[action] passportStats')
    console.log(payload)

    axios.get(API_BASE_PATH + '/passport/stats')
      .then(response => {
        console.log('passportStats responded')
        console.log(response)

        if (payload !== undefined && payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('passportStats failed')
        console.log(error)

        VueNoty.showErrored(error, 'パスポート情報を取得できませんでした')

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  publishPassports (ctx, payload) {
    console.log('[action] publishPassports')
    console.log(payload)

    let params = new FormData()
    params.append('count', payload.count)

    axios.post(API_BASE_PATH + '/passport/publish', params)
      .then(response => {
        console.log('publishPassports responded')
        console.log(response)

        VueNoty.showSucceeded('パスポートを発行しました')

        if (payload !== undefined && payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('publishPassports failed')
        console.log(error)

        VueNoty.showErrored(error, 'パスポートを発行できませんでした')

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  loadNewsList (ctx, payload) {
    console.log('[action] loadNewsList')
    console.log(payload)

    axios.get(API_BASE_PATH + '/news', {
      page: payload.page
    })
      .then(response => {
        console.log('loadNewsList responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('loadNewsList errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  loadNews (ctx, payload) {
    console.log('[action] loadNews')
    console.log(payload)

    axios.get(API_BASE_PATH + '/news/' + String(payload.newsId))
      .then(response => {
        console.log('loadNews responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('loadNews errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  createNews (ctx, payload) {
    console.log('[action] createNews')
    console.log(payload)

    let params = new FormData()
    params.append('news[is_new]', payload.news.isNew)
    params.append('news[title]', payload.news.title)
    params.append('news[content]', payload.news.content)
    params.append('news[url]', payload.news.url)
    params.append('news[publish_at]', payload.news.publishAt)

    if (payload.news.closeAt !== undefined) {
      params.append('news[close_at]', payload.news.closeAt)
    }

    console.log(params)

    axios.post(API_BASE_PATH + '/news/', params)
      .then(response => {
        console.log('createNews responded')
        console.log(response)

        VueNoty.showSucceeded('お知らせを登録しました')

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('createNews errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  updateNews (ctx, payload) {
    console.log('[action] updateNews')
    console.log(payload)

    let params = new FormData()
    params.append('news[is_new]', payload.news.isNew)
    params.append('news[title]', payload.news.title)
    params.append('news[content]', payload.news.content)
    params.append('news[url]', payload.news.url)
    params.append('news[publish_at]', payload.news.publishAt)

    if (payload.news.closeAt !== undefined) {
      params.append('news[close_at]', payload.news.closeAt)
    }

    console.log(params)

    axios.post(API_BASE_PATH + '/news/' + String(payload.news.id), params)
      .then(response => {
        console.log('updateNews responded')
        console.log(response)

        VueNoty.showSucceeded('お知らせを更新しました')

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('updateNews errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  changeOrderNews (ctx, payload) {
    console.log('[action] changeOrderNews')
    console.log(payload)

    axios.post(
      API_BASE_PATH +
      '/news/' + String(payload.newsId) +
      '/change_order', {
        method: payload.method,
        value: payload.value,
      })
      .then(response => {
        console.log('changeOrderNews responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('changeOrderNews errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  deleteNews (ctx, payload) {
    console.log('[action] deleteNews')
    console.log(payload)

    axios.post(
      API_BASE_PATH + '/news/' + String(payload.newsId) + '/delete')
      .then(response => {
        console.log('deleteNews responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('deleteNews errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  loadFaqList (ctx, payload) {
    console.log('[action] loadFaqList')
    console.log(payload)

    axios.get(API_BASE_PATH + '/faq', {
      page: payload.page
    })
      .then(response => {
        console.log('loadFaqList responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('loadFaqList errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  loadFaq (ctx, payload) {
    console.log('[action] loadFaq')
    console.log(payload)

    axios.get(API_BASE_PATH + '/faq/' + String(payload.faqId))
      .then(response => {
        console.log('loadFaq responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('loadFaq errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  createFaq (ctx, payload) {
    console.log('[action] createFaq')
    console.log(payload)

    let params = new FormData()
    params.append('faq[published]', payload.faq.published)
    params.append('faq[category]', payload.faq.category)
    params.append('faq[question]', payload.faq.question)
    params.append('faq[answer]', payload.faq.answer)

    console.log(params)

    axios.post(API_BASE_PATH + '/faq/', params)
      .then(response => {
        console.log('createFaq responded')
        console.log(response)

        VueNoty.showSucceeded('FAQを登録しました')

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('createFaq errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  updateFaq (ctx, payload) {
    console.log('[action] updateFaq')
    console.log(payload)

    let params = new FormData()
    params.append('faq[published]', payload.faq.published)
    params.append('faq[category]', payload.faq.category)
    params.append('faq[question]', payload.faq.question)
    params.append('faq[answer]', payload.faq.answer)

    console.log(params)

    axios.post(API_BASE_PATH + '/faq/' + String(payload.faq.id), params)
      .then(response => {
        console.log('updateFaq responded')
        console.log(response)

        VueNoty.showSucceeded('FAQを更新しました')

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('updateFaq errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  changeOrderFaq (ctx, payload) {
    console.log('[action] changeOrderFaq')
    console.log(payload)

    axios.post(
      API_BASE_PATH +
      '/faq/' + String(payload.faqId) +
      '/change_order', {
        method: payload.method,
        value: payload.value,
      })
      .then(response => {
        console.log('changeOrderFaq responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('changeOrderFaq errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },

  deleteFaq (ctx, payload) {
    console.log('[action] deleteFaq')
    console.log(payload)

    axios.post(
      API_BASE_PATH + '/faq/' + String(payload.faqId) + '/delete')
      .then(response => {
        console.log('deleteFaq responded')
        console.log(response)

        if (payload.succeeded !== undefined) {
          payload.succeeded(response.data)
        }
      })
      .catch(error => {
        console.log('deleteFaq errored')
        console.log(error)

        VueNoty.showErrored(error)

        if (payload.failed !== undefined) {
          payload.failed(error)
        }
      })
  },
}
