<template>
  <v-container class="passport">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-city-variant-outline</v-icon>&nbsp;
      &nbsp;
      パスポート管理
    </v-toolbar>

    <v-divider />

    <v-card class="stats">
      <v-card-title class="text-sm-body-1">利用状況</v-card-title>
      <v-divider />
      <v-card-text class="stats-content" v-if="stats !== null">
        <v-row class="stats-row">
          <v-col cols="2" class="stats-name">発行総数</v-col>
          <v-col cols="2" class="stats-value">{{ stats.total.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="2" class="stats-name">登録済み</v-col>
          <v-col cols="2" class="stats-value">{{ stats.actives.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
        </v-row>

        <v-row class="stats-row">
          <v-col cols="2" class="stats-name">利用者数（昨日）</v-col>
          <v-col cols="2" class="stats-value">{{ stats.yesterday.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="2" class="stats-name"></v-col>
          <v-col cols="2" class="stats-value"></v-col>
          <v-col cols="2">&nbsp;</v-col>
        </v-row>

        <v-row class="stats-row">
          <v-col cols="2" class="stats-name">利用者数（今週）</v-col>
          <v-col cols="2" class="stats-value">{{ stats.thisWeek.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="2" class="stats-name">利用者数（先週）</v-col>
          <v-col cols="2" class="stats-value">{{ stats.lastWeek.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
        </v-row>

        <v-row class="stats-row">
          <v-col cols="2" class="stats-name">利用者数（今月）</v-col>
          <v-col cols="2" class="stats-value">{{ stats.thisMonth.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="2" class="stats-name">利用者数（先月）</v-col>
          <v-col cols="2" class="stats-value">{{ stats.lastMonth.toLocaleString() }}</v-col>
          <v-col cols="2">&nbsp;</v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="passports">
      <v-card-title class="text-sm-body-1">発行済みパスポート</v-card-title>
      <v-divider />
      <v-card-text>
        <div class="notes" style="margin-bottom: 5px;">
          ユーザ登録済みのパスポートのデータ（登録情報を含む）をダウンロードできます<br />
          未登録を含む全件ダウンロードはシステム管理担当者までご依頼ください。
        </div>
        <div class="notes" style="margin-bottom: 10px; color: red;">
          ※「登録済みパスポートデータ（前日分まで）」は、前日24：00までのデータです。<br />
          　当日00：00からのデータは、登録済みパスポートデータ（当日分のみ）から取得してください。
        </div>
        &nbsp;&nbsp;
        <v-btn
            color="success"
            @click="downloadList"
        >
          登録済みパスポートデータ（前日分まで）
        </v-btn>
        &nbsp;&nbsp;
        <v-btn
            color="success"
            @click="downloadTodayList"
        >
          登録済みパスポートデータ（当日分のみ）
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="publish">
      <v-card-title class="text-sm-body-1">パスポート発行</v-card-title>
      <v-divider />
      <v-card-text>
        <div class="notes">
          最大10,000件まで１度に発行できます
        </div>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="count"
              :rules="countRules"
              label="発行数（1〜10,000）"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              color="success"
              @click="publish"
              class="action-button"
              :disabled="!submittable"
            >
              パスポート発行
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="qrcode">
      <v-card-title class="text-sm-body-1">パスポートQRコード</v-card-title>
      <v-divider />
      <v-card-text>
        <div class="notes">
          最大1,000件まで一度にダウンロードできます<br />
          ダウンロードしたいパスポートIDの範囲を指定してください
        </div>
        <div class="passport-id-range" v-if="this.stats !== null">
          ［パスポートID］ {{ this.stats.minId }} 〜 {{ this.stats.maxId }}
        </div>
        <v-row>
          <v-col cols="2">
            <v-text-field
              v-model="beginId"
              :rules="beginIdRules"
              label="先頭ID"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="endId"
              :rules="endIdRules"
              label="末尾ID"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              color="success"
              @click="downloadQrCodes"
              class="action-button"
            >
              QRコード ダウンロード
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-overlay :value="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      stats: null,

      count: null,
      countRules: [
        v => !!v || v > 0 || v <= 10000 || '発行数を正しく入力してください'
      ],

      beginId: null,
      beginIdRules: [
        v => !!v || v > 0 || '先頭IDを正しく入力してください'
      ],

      endId: null,
      endIdRules: [
        v => !!v || v > 0 || '末尾IDを正しく入力してください'
      ],

      submittable: true,
      progress: false
    }
  },
  computed: {
  },
  mounted: function () {
    this.updateStats()
  },
  methods: {
    updateStats () {
      console.log('[updateStats]')

      this.$store.dispatch('passportStats', {
        succeeded: function (data) {
          this.stats = data.stats
        }.bind(this)
      })
    },

    downloadList () {
      if (!confirm('登録済みパスポートデータ（前日分まで）をダウンロードしますか？')) {
        return
      }

      window.open('/admin/api/passport/download_list')
    },

    downloadTodayList () {
      if (!confirm('登録済みパスポートデータ（当日分のみ）をダウンロードしますか？')) {
        return
      }

      window.open('/admin/api/passport/download_today_list')
    },

    downloadQrCodes () {
      if (this.beginId === undefined || this.beginId === null || this.beginId < 0) {
        alert('先頭IDを入力してください')
        return
      }
      if (this.endId === undefined || this.endId === null || this.endId < 0) {
        alert('末尾IDを入力してください')
        return
      }
      if (this.beginId < this.stats.minId || this.stats.maxId < this.beginId) {
        alert('先頭IDの値が正しくありません')
        return
      }
      if (this.endId < this.stats.minId || this.stats.maxId < this.endId) {
        alert('末尾IDの値が正しくありません')
        return
      }
      if (this.beginId > this.endId) {
        alert('先頭IDは末尾IDと同じか、より小さい値を指定してください')
        return
      }
      if (this.endId - this.beginId > 1000) {
        alert('末尾IDは、先頭ID + 1000 までの値で指定してください')
        return
      }

      if (!confirm('パスポートQRコード画像をダウンロードしますか？')) {
        return
      }

      window.open('/admin/api/passport/download_qr_codes?b=' + String(this.beginId) + '&e=' + String(this.endId))
    },

    publish () {
      if (this.count === undefined || this.count === null || this.count < 0 || this.count > 10000) {
        alert('発行件数を入力してください')
        return
      }

      if (!confirm(String(this.count) + '件のパスポートを発行しますか？？')) {
        return
      }

      this.submittable = false
      this.progress = true

      this.$store.dispatch('publishPassports', {
        count: this.count,
        succeeded: function () {
          this.progress = false
          this.submittable = true

          this.updateStats()
        }.bind(this),
        failed: function () {
          this.progress = false
          this.submittable = true
        }.bind(this)
      })
    }
  }
}
</script>

<style scoped>
.stats {
  margin-top: 20px;
}

.passports {
  margin-top: 20px;
}

.publish {
  margin-top: 20px;
}

.qrcode {
  margin-top: 20px;
}

.stats-content {
  padding: 25px;
}

.stats-row {

}

.stats-row .col {
  padding: 6px 12px;
}

.stats-name {
  font-size: 0.9rem;
  color: grey;
}

.stats-value {
  font-size: 1.1rem;
  color: dimgrey;
  text-align: right;
}

.notes {
  font-size: 0.8rem;
  color: grey;
}

.action-button {
  margin-top: 10px;
}

.passport-id-range {
  font-size: 1.0rem;
  color: orangered;
  margin: 10px 0 0 0;
}
</style>
