<template>
  <v-container class="news-list">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-newspaper-variant-outline</v-icon>
      &nbsp;
      お知らせリスト

      <v-spacer></v-spacer>

      <span class="list-page-info" v-if="totalCount !== null && totalCount > 0">
        {{ limit * (page - 1) + 1 }}〜{{ limit * page > totalCount ? totalCount : limit * page }}件（全{{ totalCount }}件）
      </span>

      <v-btn color="success" to="/news/new" class="no-text-decoration-white">
        お知らせを追加
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-simple-table v-if="newsList !== null">
      <template v-slot:default>
        <thead>
        <tr>
          <td width="8%">ID</td>
          <td width="12%">状態</td>
          <td width="30%">件名</td>
          <td width="15%">開始日時</td>
          <td width="15%">終了日時</td>
          <td width="20%">操作・表示順変更</td>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="news in newsList"
          :key="news.id"
          class="news-list-item"
        >
          <td>{{ news.id }}</td>
          <td>{{ newsPublishedStatus(news) }}</td>
          <td>{{ news.title }}</td>
          <td>{{ news.publishAt }}</td>
          <td>{{ news.closeAt }}</td>
          <td>
            <v-btn title="表示順を１つ上げる" x-small @click="moveUp(news.id)" :disabled="news.displayOrder === totalCount">↑</v-btn>
            &nbsp;
            <v-btn x-small color="primary" @click="goToDetail(news.id)">詳細</v-btn>
            &nbsp;
            <v-btn title="表示順を１つ下げる" x-small @click="moveDown(news.id)" :disabled="news.displayOrder === 1">↓</v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-center pagination" v-if="totalPages > 1">
      <v-pagination
        v-model="page"
        :length="this.totalPages"
        :total-visible="10"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import newsHelper from '../../../../lib/newsHelper'

export default {
  data: function () {
    return {
      newsList: null,
      page: null,
      totalPages: null,
      totalCount: null,
      limit: null,

      submittable: true,
      progress: false,
    }
  },
  watch: {
    page (newValue) {
      this.updateNews(newValue)
    }
  },
  computed: {
  },
  mounted: function () {
    this.updateNews(1)
  },
  methods: {
    updateNews (page) {
      console.log('[updateNews] ' + String(page))

      this.$store.dispatch('loadNewsList', {
        succeeded: function (data) {
          this.newsList = data.newsList
          this.page = data.currentPage
          this.totalPages = data.totalPages
          this.totalCount = data.totalCount
          this.limit = data.limit
        }.bind(this),
        page: page
      })
    },

    newsPublishedStatus (news) {
      if (newsHelper.published(news.publishAt)) {
        return (newsHelper.closed(news.closeAt)) ? '公開終了' : '公開中'
      } else {
        return '未公開'
      }
    },

    moveDown (newsId) {
      console.log('[news:moveDown] ' + String(newsId))

      if (!this.submittable) {
        return
      }

      this.submittable = false

      let index = 0
      for (const n of this.newsList) {
        if (n.id === newsId) {
          break
        }
        index += 1
      }
      console.log('index: ' + String(index))

      this.progress = true

      this.$store.dispatch('changeOrderNews', {
        newsId: newsId,
        method: 'dec',
        value: 1,
        succeeded: function (data) {
          if (index === this.newsList.length - 1) {
            this.updateNews(this.page)
          } else {
            this.newsList[index + 1].displayOrder += 1
            this.newsList[index].displayOrder -= 1

            let e = this.newsList[index + 1]
            this.newsList[index + 1] = this.newsList[index]
            this.newsList[index] = e
          }

          this.progress = false
          this.submittable = true
        }.bind(this),
        failed: function () {
          this.progress = false
          alert('順序を変更できませんでした')
          this.submittable = true
        }.bind(this)
      })
    },

    moveUp (newsId) {
      console.log('[news:moveUp] ' + String(newsId))

      if (!this.submittable) {
        return
      }

      this.submittable = false

      let index = 0
      for (const n of this.newsList) {
        if (n.id === newsId) {
          break
        }
        index += 1
      }

      this.progress = true

      this.$store.dispatch('changeOrderNews', {
        newsId: newsId,
        method: 'inc',
        value: 1,
        succeeded: function (data) {
          if (index === 0) {
            this.updateNews(this.page)
          } else {
            this.newsList[index].displayOrder += 1
            this.newsList[index - 1].displayOrder -= 1

            let e = this.newsList[index - 1]
            this.newsList[index - 1] = this.newsList[index]
            this.newsList[index] = e
          }

          this.progress = false
          this.submittable = true
        }.bind(this),
        failed: function () {
          this.progress = false
          alert('順序を変更できませんでした')
          this.submittable = true
        }.bind(this)
      })
    },

    goToDetail (newsId) {
      this.$router.push('/news/' + String(newsId))
    },
  }
}
</script>

<style scoped>
.news-list thead {
  color: silver;
}
.news-list-item {

}
.pagination {
  margin-top: 20px;
}
</style>
