<template>
  <v-container class="news-form">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-newspaper-variant-outline</v-icon>
      &nbsp;
      お知らせの作成・編集
    </v-toolbar>

    <v-form
      v-model="valid"
      ref="form"
    >
      <v-row justify="space-between">
        <v-col cols="2" md="2">
          <p class="news-form-caption">NEW表示</p>
        </v-col>

        <v-col cols="10" md="10">
          <v-radio-group
              v-model="isNew"
              class="news-form-is-new-radios"
              row
          >
            <v-radio
                key="is_new"
                label="NEW表示あり"
                value="1"
            >
            </v-radio>
            &nbsp;&nbsp;
            <v-radio
                key="is_not_new"
                label="なし"
                value="0"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <v-text-field
            v-model="title"
            :rules="titleRules"
            :counter="50"
            label="件名"
          />
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <rich-editor
            ref="richEditor"
            :initial-value="content"
          ></rich-editor>
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <v-text-field
              v-model="url"
              :rules="urlRules"
              :counter="200"
              label="外部URL"
          />
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="3" md="3">
          <p class="news-form-caption news-form-caption-period">公開日時（必須）</p>
        </v-col>

        <v-col cols="2" md="2">
          <v-menu
            v-model="publishAtDateMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="publishAtDate"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                @change="publishAtDateChanged"
                @click:clear="publishAtDate = null; publishAtTime = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="publishAtDate"
              locale="ja"
              @change="publishAtDateChanged"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2" md="2">
          <v-select
            v-if="times !== null"
            v-model="publishAtTime"
            :items="times"
            class="news-form-time-selector"
            label="時刻"
            solo
            dense
          ></v-select>
        </v-col>

        <v-col cols="5" md="5">&nbsp;</v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="3" md="3">
          <p class="news-form-caption news-form-caption-period">公開終了日時（任意）</p>
        </v-col>

        <v-col cols="2" md="2">
          <v-menu
            v-model="closeAtDateMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="closeAtDate"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                @change="closeAtDateChanged"
                @click:clear="closeAtDate = null; closeAtTime = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="closeAtDate"
              locale="ja"
              @change="closeAtDateChanged"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2" md="2">
          <v-select
            v-if="times !== null"
            v-model="closeAtTime"
            :items="times"
            class="news-form-time-selector"
            label="時刻"
            solo
            dense
          ></v-select>
        </v-col>

        <v-col cols="5" md="5">&nbsp;</v-col>
      </v-row>

      <v-row class="news-form-actions">
        <v-spacer></v-spacer>

        <v-btn @click="$router.back()">
          保存せずに戻る
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="success"
          @click="submit"
          :disabled="!submittable"
        >
          {{ this.submitButtonCaption() }}
        </v-btn>

        <v-spacer></v-spacer>
      </v-row>
    </v-form>

    <v-overlay :value="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import newsHelper from '../../../../lib/newsHelper'
import RichEditor from '../common/RichEditor.vue'

export default {
  components: {
    RichEditor
  },
  data: function () {
    return {
      valid: false,

      urlRegex: /https?:\/\/[\w!\?\/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+/,

      isNew: '0',
      isNewRules: [
        v => v === '1' || v === '0' || 'NEW表示状態を選択してください',
      ],


      title: '',
      titleRules: [
        v => !!v || '件名を入力してください',
        v => v.length <= 50 || '50文字以内で入力してください'
      ],

      content: '',
      contentRules: [
        v => !v || v.length <= 2000 || '2000文字以内で入力してください'
      ],

      url: '',
      urlRules: [
        v => !v || v.length <= 200 || '200文字以内で入力してください',
        v => !v || this.urlRegex.test(v) || 'URLとして正しくない文字列です',
      ],

      publishAt: null,
      publishAtDateMenu: false,
      publishAtDate: null,
      publishAtTime: null,

      closeAt: null,
      closeAtDateMenu: false,
      closeAtDate: null,
      closeAtTime: null,

      times: null,

      submittable: true,
      progress: false
    }
  },
  watch: {
  },
  computed: {
    newsId () {
      return this.$route.params.id === undefined ? undefined : parseInt(this.$route.params.id)
    }
  },
  mounted: function () {
    this.setupTimes()

    if (this.newsId !== undefined) {
      this.$store.dispatch('loadNews', {
        newsId: this.newsId,
        succeeded: function (data) {
          this.isNew = data.news.isNew ? '1' : '0'

          this.title = data.news.title
          this.content = data.news.content
          this.url = data.news.url

          this.publishAt = data.news.publishAt
          const pa = newsHelper.extractDateTime(this.publishAt)
          this.publishAtDate = pa[0]
          this.publishAtTime = pa[1]

          this.closeAt = data.news.closeAt
          const ca = newsHelper.extractDateTime(this.closeAt)
          this.closeAtDate = ca[0]
          this.closeAtTime = ca[1]

          this.$refs.richEditor.setValue(this.content)
        }.bind(this)
      })
    }
  },
  methods: {
    setupTimes () {
      this.times = newsHelper.generateTimes()
    },

    publishAtDateChanged () {
      if (this.publishAtTime === null) {
        this.publishAtTime = '00:00'
      }

      this.publishAtDateMenu = false
    },

    closeAtDateChanged () {
      if (this.closeAtTime === null) {
        this.closeAtTime = '00:00'
      }

      this.closeAtDateMenu = false
    },

    submitButtonCaption () {
      return this.submittable ? '保存する' : '保存中...'
    },

    submit () {
      this.submittable = false

      if (!this.$refs.form.validate()) {
        this.submittable = true
        return
      }

      if (this.publishAtDate === null) {
        alert('公開日を指定してください')
        this.submittable = true
        return
      }
      if (this.publishAtTime === null) {
        alert('公開時刻を指定してください')
        this.submittable = true
        return
      }

      this.content = this.$refs.richEditor.getValue()

      if (this.content === '' && this.url === '') {
        alert('お知らせ本文もしくは外部URLのいずれか一方は必ず入力してください')
        this.submittable = true
        return
      }

      if (this.content !== '' && this.url !== '') {
        if (!confirm('お知らせ本文と外部URLの双方が入力されている場合、外部URLの指定が優先されます。よろしいですか？')) {
          this.submittable = true
          return
        }
      }

      this.publishAt = newsHelper.buildDateTime(this.publishAtDate, this.publishAtTime)
      this.closeAt = newsHelper.buildDateTime(this.closeAtDate, this.closeAtTime)

      const actionType = this.newsId === undefined ? 'createNews' : 'updateNews'
      console.log('actionType: ' + actionType)

      let params = {
        isNew: this.isNew === '1',
        title: this.title,
        content: this.content,
        url: this.url,
        publishAt: this.publishAt,
        closeAt: this.closeAt,
      }

      if (actionType !== 'createNews') {
        params.id = this.newsId
      }

      this.progress = true

      this.$store.dispatch(actionType, {
        news: params,
        succeeded: function (data) {
          this.progress = false
          this.$router.push('/news/' + String(data.news.id))
        }.bind(this),
        failed: function () {
          this.progress = false
          alert('保存できませんでした')
          this.submittable = true
        }.bind(this)
      })
    }
  }
}
</script>

<style scoped>
.news-form {

}
.news-form-caption {
  font-size: 0.9em;
  color: grey;
  text-align: right;
  margin: 5px 30px 0 0;
}
.news-form-caption-period {
  margin-top: 21px;
}
.news-form-time-selector {
  margin-top: 12px !important;
}
.news-form-actions {
  padding: 10px 0 20px 0;
}
</style>
