<template>
  <v-app-bar
      fixed
      dense
      max-height="64"
      :color="color_main"
  >
    <v-toolbar-title class="title-image">
      ぐーちょきパスポート 管理画面
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>
      <v-btn text to="/passport">
        <v-icon>mdi-city-variant-outline</v-icon>&nbsp;
        パスポート管理
      </v-btn>
    </v-toolbar-items>

    <v-toolbar-items>
      <v-btn text to="/news">
        <v-icon>mdi-newspaper-variant-outline</v-icon>&nbsp;
        お知らせ管理
      </v-btn>
    </v-toolbar-items>

    <v-toolbar-items>
      <v-btn text to="/faq">
        <v-icon>mdi-help-circle-outline</v-icon>&nbsp;
        FAQ管理
      </v-btn>
    </v-toolbar-items>

    <v-menu offset-y v-if="account() !== null">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="menu-item-clickable">
          <v-list-item-title>{{ account().name }}</v-list-item-title>
        </v-list-item>

        <v-list-item class="menu-item-clickable">
          <v-list-item-title @click="logout()">ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import theme from '../../theme.js'

export default {
  computed: {
    color_main () {
      return theme.color_main()
    }
  },

  methods: {
    account () {
      console.log('[account]')
      console.log(this.$store.getters.account)
      return this.$store.getters.account
    },

    logout () {
      if (!confirm('ログアウトしますか？')) {
        return
      }

      let form = document.createElement('form')
      form.method = 'POST'
      form.action = '/admin/logout'

      let p_token = document.createElement('input')
      p_token.type = 'hidden'
      p_token.name = 'authenticity_token'
      const tags = document.getElementsByTagName('meta')
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].name === 'csrf-token') {
          p_token.value = tags[i].content
          break
        }
      }
      form.appendChild(p_token)

      document.body.appendChild(form)
      form.submit()
    }
  }
}
</script>

<style scoped>
.menu-item-clickable {
  cursor: pointer;
}
</style>
