<template>
  <v-container class="faq-detail">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-help-circle-outline</v-icon>
      &nbsp;
      <span>FAQ詳細</span>
    </v-toolbar>

    <v-card flat>
      <v-card-text class="faq-detail-tabs-items">
        <v-row justify="space-between" class="faq-detail-item">
          <v-col cols="2" md="2" class="faq-detail-item-label">ID</v-col>
          <v-col cols="10" md="10" class="faq-detail-item-value" v-if="faq !== null">{{ faq.id }}</v-col>
        </v-row>

        <v-row justify="space-between" class="faq-detail-item">
          <v-col cols="2" md="2" class="faq-detail-item-label">状態</v-col>
          <v-col cols="10" md="10" class="faq-detail-item-value" v-if="faq !== null">
            {{ faq.published ? '公開中' : '下書き／非公開' }}
          </v-col>
        </v-row>

        <v-row justify="space-between" class="faq-detail-item">
          <v-col cols="2" md="2" class="faq-detail-item-label">カテゴリ</v-col>
          <v-col cols="10" md="10" class="faq-detail-item-value" v-if="faq !== null">{{ categoryName(faq) }}</v-col>
        </v-row>

        <v-row justify="space-between" class="faq-detail-item">
          <v-col cols="2" md="2" class="faq-detail-item-label">質問</v-col>
          <v-col cols="10" md="10" class="faq-detail-item-value faq-detail-content faq-detail-question" v-if="faq !== null">{{ faq.question }}</v-col>
        </v-row>

        <v-row justify="space-between" class="faq-detail-item">
          <v-col cols="2" md="2" class="faq-detail-item-label">回答</v-col>
          <v-col cols="10" md="10" class="faq-detail-item-value faq-detail-content faq-detail-answer" v-if="faq !== null" v-html="faq.answer"></v-col>
        </v-row>

        <v-row justify="space-between" class="faq-detail-actions">
          <v-col cols="1" md="1">&nbsp;</v-col>
          <v-col cols="10" md="10">
            <v-row justify="space-between">
              <v-btn to="/faq/">
                一覧に戻る
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                v-if="faq !== null"
                color="error"
                @click="deleteFaq(faq.id)"
              >
                FAQを削除
              </v-btn>

              &nbsp;&nbsp;&nbsp;&nbsp;

              <v-btn
                v-if="faq !== null"
                color="warning"
                :to="'/faq/' + String(faqId) + '/edit'"
              >
                FAQを編集
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="2" md="2">&nbsp;</v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import faqHelper from '../../../../lib/faqHelper'

export default {
  data: function () {
    return {
      faq: null,

      submittable: true,
    }
  },
  watch: {
  },
  computed: {
    faqId () {
      return parseInt(this.$route.params.id)
    },
  },
  mounted: function () {
    this.$store.dispatch('loadFaq', {
      faqId: this.faqId,
      succeeded: function (data) {
        this.faq = data.faq
      }.bind(this)
    })
  },
  methods: {
    categoryName (faq) {
      return faqHelper.categoryName(faq.category)
    },

    deleteFaq () {
      console.log('deleteFaq')

      if (!confirm('削除すると元に戻すことはできません。\nこのFAQを削除しますか？')) {
        return
      }

      if (!this.submittable) {
        return
      }

      this.submittable = false

      this.$store.dispatch('deleteFaq', {
        faqId: this.faqId,
        succeeded: function (data) {
          this.$router.push('/faq/')
        }.bind(this),
        failed: function () {
          alert('削除できませんでした')
          this.submittable = true
        }.bind(this)
      })
    },
  }
}
</script>

<style scoped>
.faq-detail {

}
.faq-detail-item {

}
.faq-detail-item-label {
  color: grey;
  text-decoration: underline;
}
.faq-detail-item-value {
}
.faq-detail-content {
  border: 1px solid silver;
  border-radius: 3px;
  padding: 5px 10px;
}
.faq-detail-question {
  margin: 10px 0;
}
.faq-detail-answer {
}
.faq-detail-actions {
  margin-top: 30px !important;
}
</style>
