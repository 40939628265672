export default {
  generateTimes () {
    const hours = [
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
      '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23',
    ]

    let times = []

    for (const h of hours) {
      times.push(h + ':00')
    }

    return times
  },

  extractDateTime (value) {
    if (value === '') {
      return [null, null]
    }

    if (value.match(/\d\d\d\d-\d\d-\d\d \d\d:\d\d/) === null) {
      console.log('[extractDateTime] ERROR: bad value format [' + String(value) + ']')
      return [null, null]
    }

    return value.split(' ')
  },

  buildDateTime (date, time) {
    if (date === null) {
      return null
    }

    return String(date) + ' ' + String(time)
  },

  todayNum () {
    const today = new Date()

    return today.getFullYear() * 1000000 +
      (today.getMonth() + 1) * 10000 +
      today.getDate() * 100 +
      today.getHours()
  },

  dateStringToNum (str) {
    const targetDate = str.slice(0, 4) + str.slice(5, 7) + str.slice(8, 10) + str.slice(11, 13)
    return parseInt(targetDate)
  },

  published (publishDate) {
    return this.dateStringToNum(publishDate) <= this.todayNum()
  },

  closed (closeDate) {
    if (closeDate === null || closeDate === '') {
      return false
    }

    return this.dateStringToNum(closeDate) <= this.todayNum()
  },
}
