import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

const jar = new CookieJar();
const client = wrapper(axios.create({ jar }));

const setToken = (params) => {
  const tags = document.getElementsByTagName('meta')
  for (let i = 0; i < tags.length; i++) {
    if (tags[i].name === 'csrf-token') {
      if (params.constructor === FormData) {
        params.append('authenticity_token', tags[i].content)
      } else {
        params.authenticity_token = tags[i].content
      }
      break
    }
  }
  return params
}

export default {
  get: (path, params = {}) => {
    return client.get(path, { params })
  },
  post: (path, params = {}) => {
    return client.post(path, setToken(params))
  },
  put: (path, params = {}) => {
    return client.put(path, setToken(params))
  },
  delete: (path, params = {}) => {
    return client.delete(path, { params: setToken(params) })
  },
}
