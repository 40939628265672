export default {
  categoryName (categoryId) {
    if (categoryId === 100) {
      return '利用登録'
    }
    if (categoryId === 200) {
      return '利用'
    }

    return ''
  },

  categoryList () {
    return [
      { text: '利用登録', value: 100 },
      { text: '利用', value: 200 },
    ]
  },
}
