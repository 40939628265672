<template>
  <div>
    <input
      type="hidden"
      id="trix_input"
      :value="initialValue"
    />
    <trix-toolbar
      id="trix_toolbar"
    >
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools"
              data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold"
                  data-trix-attribute="bold" data-trix-key="b" title="太字"
                  tabindex="-1">Bold</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic"
                  data-trix-attribute="italic" data-trix-key="i" title="斜体"
                  tabindex="-1">Italic</button>
        </span>
        <span class="trix-button-group trix-button-group--text-tools"
              data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link"
                  data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="リンク"
                  tabindex="-1">Link</button>
        </span>
        <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list"
                  data-trix-attribute="bullet" title="箇条書き" tabindex="-1">Bullets</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list"
                  data-trix-attribute="number" title="番号リスト" tabindex="-1">Numbers</button>
        </span>
        <span class="trix-button-group trix-button-group--text-tools"
              data-trix-button-group="text-tools">
          <button type="button"
                  class="trix-button trix-button--icon trix-button--icon-increase-nesting-level"
                  data-trix-action="increaseNestingLevel" title="インデント" tabindex="-1"
                  disabled="">Increase Level</button>
          <button type="button"
                  class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level"
                  data-trix-action="decreaseNestingLevel" title="インデント解除" tabindex="-1"
                  disabled="">Decrease Level</button>
        </span>
        <span class="trix-button-group-spacer"></span>
        <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo"
                  data-trix-action="undo" data-trix-key="z" title="元に戻す" tabindex="-1" disabled="">Undo</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo"
                  data-trix-action="redo" data-trix-key="shift+z" title="やり直し" tabindex="-1"
                  disabled="">Redo</button>
        </span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="URL" aria-label="リンク先" required data-trix-input>
            <div class="trix-button-group">
              <input type="button" class="trix-button trix-button--dialog" value="リンク設定" data-trix-method="setAttribute">
              <input type="button" class="trix-button trix-button--dialog" value="リンク削除" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      </div>
    </trix-toolbar>
    <trix-editor
      ref="trix"
      input="trix_input"
      toolbar="trix_toolbar"
      class="article-text-trix-editor"
    ></trix-editor>
  </div>
</template>

<script>
import Trix from 'trix'

export default {
  props: {
    initialValue: String,
  },

  mounted: function () {
    if (this.initialValue !== undefined && this.initialValue !== '') {
      this.$refs.trix.editor.loadHTML(this.initialValue)
    }
  },

  methods: {
    getValue () {
      const v = document.getElementById('trix_input').value

      if (v.includes('　')) {
        alert('全角スペースは半角スペースに変換されます')
      }

      return v.replaceAll('　', '&nbsp;')
    },

    setValue (content) {
      this.$refs.trix.editor.loadHTML(content)
    },

    validate () {
      if (document.getElementById('trix_input').value === '') {
        alert('テキストを入力してください')
        return false
      }
      return true
    },
  }
}
</script>

<style scoped>
</style>
