import Vue from 'vue'
import Vuex from 'vuex'
import * as gettersConf from './getters'
import * as actionsConf from './actions'
import * as mutationsConf from './mutations'

Vue.use(Vuex)

const state = {
  account: null
}

export default new Vuex.Store({
  state,
  getters: gettersConf['getterTypes'],
  actions: actionsConf['actionTypes'],
  mutations: mutationsConf['mutationTypes'],
})
