<template>
  <v-container class="faq-list">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-help-circle-outline</v-icon>
      &nbsp;
      FAQリスト

      <v-spacer></v-spacer>

      <v-btn color="success" to="/faq/new" class="no-text-decoration-white">
        FAQを追加
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-simple-table v-if="faqList !== null">
      <template v-slot:default>
        <thead>
        <tr>
          <td width="8%">ID</td>
          <td width="10%">状態</td>
          <td width="15%">カテゴリ</td>
          <td width="47%">質問</td>
          <td width="20%">操作・表示順変更</td>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="faq in faqList"
          :key="faq.id"
          class="faq-list-item"
        >
          <td>{{ faq.id }}</td>
          <td>{{ faq.published ? '公開中' : '下書き' }}</td>
          <td>{{ categoryName(faq) }}</td>
          <td>{{ faq.question }}</td>
          <td>
            <v-btn title="表示順を１つ上げる" x-small @click="moveUp(faq.id)" :disabled="faq.displayOrder === 1">↑</v-btn>
            &nbsp;
            <v-btn x-small color="primary" @click="goToDetail(faq.id)">詳細</v-btn>
            &nbsp;
            <v-btn title="表示順を１つ下げる" x-small @click="moveDown(faq.id)" :disabled="faq.displayOrder === faqList.length">↓</v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-center pagination" v-if="totalPages > 1">
      <v-pagination
        v-model="page"
        :length="this.totalPages"
        :total-visible="10"
      ></v-pagination>
    </div>

    <v-overlay :value="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import faqHelper from '../../../../lib/faqHelper'

export default {
  data: function () {
    return {
      faqList: null,
      page: null,
      totalPages: null,
      totalCount: null,
      limit: null,

      submittable: true,
      progress: false,
    }
  },
  watch: {
    page (newValue) {
      this.updateFaq(newValue)
    }
  },
  computed: {
  },
  mounted: function () {
    this.updateFaq(1)
  },
  methods: {
    updateFaq (page) {
      console.log('[updateFaq] ' + String(page))

      this.$store.dispatch('loadFaqList', {
        succeeded: function (data) {
          this.faqList = data.faqList
          this.page = data.currentPage
          this.totalPages = data.totalPages
          this.totalCount = data.totalCount
          this.limit = data.limit
        }.bind(this),
        page: page
      })
    },

    categoryName (faq) {
      return faqHelper.categoryName(faq.category)
    },

    moveUp (faqId) {
      console.log('[faq:moveUp] ' + String(faqId))

      if (!this.submittable) {
        return
      }

      this.submittable = false

      let index = 0
      for (const f of this.faqList) {
        if (f.id === faqId) {
          break
        }
        index += 1
      }
      console.log('index: ' + String(index))

      this.progress = true

      this.$store.dispatch('changeOrderFaq', {
        faqId: faqId,
        method: 'dec',
        value: 1,
        succeeded: function (data) {
          this.faqList[index - 1].displayOrder += 1
          this.faqList[index].displayOrder -= 1

          let e = this.faqList[index - 1]
          this.faqList[index - 1] = this.faqList[index]
          this.faqList[index] = e

          this.progress = false
          this.submittable = true
        }.bind(this),
        failed: function () {
          this.progress = false
          alert('順序を変更できませんでした')
          this.submittable = true
        }.bind(this)
      })
    },

    moveDown (faqId) {
      console.log('[faq:moveDown] ' + String(faqId))

      if (!this.submittable) {
        return
      }

      this.submittable = false

      let index = 0
      for (const f of this.faqList) {
        if (f.id === faqId) {
          break
        }
        index += 1
      }

      this.progress = true

      this.$store.dispatch('changeOrderFaq', {
        faqId: faqId,
        method: 'inc',
        value: 1,
        succeeded: function (data) {
          this.faqList[index].displayOrder += 1
          this.faqList[index + 1].displayOrder -= 1

          let e = this.faqList[index + 1]
          this.faqList[index + 1] = this.faqList[index]
          this.faqList[index] = e

          this.progress = false
          this.submittable = true
        }.bind(this),
        failed: function () {
          this.progress = false
          alert('順序を変更できませんでした')
          this.submittable = true
        }.bind(this)
      })
    },

    goToDetail (faqId) {
      this.$router.push('/faq/' + String(faqId))
    },
  }
}
</script>

<style scoped>
.faq-list thead {
  color: silver;
}
.faq-list-item {

}
.pagination {
  margin-top: 20px;
}
</style>
