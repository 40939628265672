import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import PassportIndex from './components/passport/Index'
import NewsList from './components/news/List'
import NewsDetail from './components/news/Detail'
import NewsForm from './components/news/Form'
import FaqList from './components/faq/List'
import FaqDetail from './components/faq/Detail'
import FaqForm from './components/faq/Form'

Vue.use(VueRouter)

const routes = [
  { path: '/passport', name: 'root', component: PassportIndex },

  { path: '/news', name: 'newsList', component: NewsList },
  { path: '/news/new', name: 'newsForm', component: NewsForm },
  { path: '/news/:id', name: 'newsDetail', component: NewsDetail },
  { path: '/news/:id/edit', name: 'newsForm', component: NewsForm },

  { path: '/faq', name: 'faqList', component: FaqList },
  { path: '/faq/new', name: 'faqForm', component: FaqForm },
  { path: '/faq/:id', name: 'faqDetail', component: FaqDetail },
  { path: '/faq/:id/edit', name: 'faqForm', component: FaqForm },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
