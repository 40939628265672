import colors from 'vuetify/lib/util/colors'

export default {
  color_main() {
    return this.color_lighten3()
  },
  color_lighten1() {
    return colors.grey.lighten1
  },
  color_lighten2() {
    return colors.grey.lighten2
  },
  color_lighten3() {
    return colors.grey.lighten3
  },
  color_lighten4() {
    return colors.grey.lighten4
  },
  color_lighten5() {
    return colors.grey.lighten5
  },
  color_darken1() {
    return colors.grey.darken1
  },
  color_darken2() {
    return colors.grey.darken2
  },
  color_darken3() {
    return colors.grey.darken3
  },
  color_darken4() {
    return colors.grey.darken4
  },
  color_accent1() {
    return colors.grey.accent1
  },
  color_accent2() {
    return colors.grey.accent2
  },
  color_accent3() {
    return colors.grey.accent3
  },
  color_accent4() {
    return colors.grey.accent4
  },
}
