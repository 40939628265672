import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from 'vuetify'
import VueNoty from 'vuejs-noty'
import Trix from 'trix'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'noty/src/themes/semanticui.scss'
import 'vuejs-noty/dist/vuejs-noty.css'
import 'trix/dist/trix.css'
import '../style/application.scss'

import adminRouter from './admin/router'
import adminStore from './admin/store'
import AdminApp from './admin/components/App'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(Vuetify)
Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: true,
  layout: 'topCenter',
  theme: 'semanticui'
})

let vuetify = new Vuetify({
  icons: { iconfont: 'mdi' }
})

Trix.config.blockAttributes.default.tagName = 'p'

document.addEventListener('DOMContentLoaded', () => {
  let element = document.getElementById('admin_app')
  if (element === null) {
    return
  }
  const params = JSON.parse(JSON.stringify(element.dataset))

  let component = {
    ...AdminApp,
    data: () => {
      return params
    }
  }

  return new Vue({
    el: element,
    store: adminStore,
    router: adminRouter,
    render: (h) => h(component),
    vuetify
  })
})
