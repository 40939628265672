<template>
  <v-app>
    <nav-bar />
    <div class="app-main">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import store from '../store'
import NavBar from './common/NavBar'
import Noty from 'noty'

export default {
  components: {
    NavBar
  },
  store,
  created: function () {
    if (this.env === 'production') {
      window.console.log = function () {}
    }

    if (this.message !== null && this.message !== "") {
      new Noty({
        type: 'info',
        text: this.message,
        timeout: 0,
        progressBar: false,
        layout: 'topCenter',
        theme: 'semanticui'
      }).show()
    }

    this.$store.dispatch('updateAccount')
  }
}
</script>

<style scoped>
.app-main {
  margin: 48px 20px 50px 20px;
}
</style>
