<template>
  <v-container class="faq-form">
    <v-toolbar class="elevation-0 content-header">
      <v-icon>mdi-help-circle-outline</v-icon>
      &nbsp;
      FAQの作成・編集
    </v-toolbar>

    <v-form
      v-model="valid"
      ref="form"
    >
      <v-row justify="space-between">
        <v-col cols="2" md="2">
          <p class="faq-form-caption">状態</p>
        </v-col>

        <v-col cols="10" md="10">
          <v-radio-group
            v-model="status"
            class="faq-form-status-radios"
            row
          >
            <v-radio
              key="status_unpublished"
              label="下書き／非公開"
              value="0"
            >
            </v-radio>
            &nbsp;&nbsp;
            <v-radio
              key="status_published"
              label="公開中"
              value="1"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="2" md="2">
          <p class="faq-form-caption">カテゴリ</p>
        </v-col>

        <v-col cols="2" md="2">
          <v-select
            v-model="category"
            :rules="categoryRules"
            :items="this.categoryItems()"
            item-text="text"
            item-value="value"
            class="faq-form-category-selector"
            label=""
            solo
            dense
          ></v-select>
        </v-col>

        <v-col cols="8" md="8">&nbsp;</v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <v-text-field
            v-model="question"
            :rules="questionRules"
            :counter="100"
            label="質問"
          />
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <rich-editor
            ref="richEditor"
            :initial-value="answer"
          ></rich-editor>
        </v-col>
      </v-row>

      <v-row class="faq-form-actions">
        <v-spacer></v-spacer>

        <v-btn @click="$router.back()">
          保存せずに戻る
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="success"
          @click="submit"
          :disabled="!submittable"
        >
          {{ this.submitButtonCaption() }}
        </v-btn>

        <v-spacer></v-spacer>
      </v-row>
    </v-form>

    <v-overlay :value="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import RichEditor from '../common/RichEditor.vue'
import faqHelper from '../../../../lib/faqHelper'

export default {
  components: {
    RichEditor
  },
  data: function () {
    return {
      valid: false,

      status: '0',
      statusRules: [
        v => v === '1' || v === '0' || '状態を選択してください',
      ],

      category: null,
      categoryRules: [
        v => !!v || 'カテゴリを選択してください',
      ],

      question: '',
      questionRules: [
        v => !!v || '質問を入力してください',
        v => v.length <= 100 || '100文字以内で入力してください'
      ],

      answer: '',
      answerRules: [
        v => !!v || '回答を入力してください',
        v => v.length <= 2000 || '2000文字以内で入力してください'
      ],

      submittable: true,
      progress: false
    }
  },
  watch: {
  },
  computed: {
    faqId () {
      return this.$route.params.id === undefined ? undefined : parseInt(this.$route.params.id)
    },
  },
  mounted: function () {
    if (this.faqId !== undefined) {
      this.$store.dispatch('loadFaq', {
        faqId: this.faqId,
        succeeded: function (data) {
          this.category = data.faq.category
          this.question = data.faq.question
          this.answer = data.faq.answer

          this.status = data.faq.published ? '1' : '0'
          console.log('this.status')
          console.log(this.status)

          this.$refs.richEditor.setValue(this.answer)
        }.bind(this)
      })
    }
  },
  methods: {
    categoryItems () {
      return faqHelper.categoryList()
    },

    submitButtonCaption () {
      return this.submittable ? '保存する' : '保存中...'
    },

    submit () {
      this.submittable = false

      if (!this.$refs.form.validate()) {
        this.submittable = true
        return
      }

      this.answer = this.$refs.richEditor.getValue()

      const actionType = this.faqId === undefined ? 'createFaq' : 'updateFaq'
      console.log('actionType: ' + actionType)

      let params = {
        published: this.status,
        category: this.category,
        question: this.question,
        answer: this.answer,
      }

      if (actionType !== 'createFaq') {
        params.id = this.faqId
      }

      this.progress = true

      this.$store.dispatch(actionType, {
        faq: params,
        succeeded: function (data) {
          this.progress = false
          this.$router.push('/faq/' + String(data.faq.id))
        }.bind(this),
        failed: function () {
          this.progress = false
          alert('保存できませんでした')
          this.submittable = true
        }.bind(this)
      })
    }
  }
}
</script>

<style scoped>
.faq-form {
}
.faq-form-caption {
  font-size: 0.9em;
  color: grey;
  text-align: right;
  margin: 10px 30px 0 0;
}
.faq-form-actions {
  padding: 10px 0 20px 0;
}
</style>
